import styled, { themeGet, device, css } from "@styled";

export const StyledSection = styled.section`
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  padding: 30px 0;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  ${device.medium} {
    padding: 40px 0;
  }
  ${device.large} {
    padding: 60px 0;
  }
  ${(props) =>
    props.layout === 2 &&
    css`
      background: #f8f8f8;
      h3 {
        color: #333;
      }
      img {
        filter: brightness(0);
      }
    `}
     ${(props) =>
    props.layout === 3 &&
    css`
      background: linear-gradient(-33deg, #15054f 0%, #571883 99%, #571883 100%);
    `}
`;

export const StyledHeading = styled.h3`
  margin-bottom: 10px;
  font-size: 22px;
  color: #fff;
  span {
    color: ${themeGet("colors.primary")};
  }
  ${device.medium} {
    font-size: 26px;
  }
  ${device.large} {
    margin-bottom: 0;
  }
  ${device.xlarge} {
    font-size: 30px;
  }
  ${device.xxlarge} {
    font-size: 39px;
  }
`;

export const StyledBG = styled.div`
  position: absolute;
  right: -68px;
  top: -35%;
  z-index: -1;
`;
