import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "@ui/wrapper";
import Button from "@ui/button";
import { HeadingType, ButtonType } from "@utils/types";
import { StyledSection, StyledHeading, StyledBG } from "./style";

const CTAArea = ({ data, layout, ...props }) => {
  return (
    <StyledSection layout={layout} {...props}>
      {
        layout !== 3 && (
          <StyledBG>
          <StaticImage src="../../../assets/images/bg/cta-bg.png" alt="CTA BG" />
        </StyledBG>
        )
      }
      <Container>
        <Row alignItems="center" textAlign={["center", null, null, "left"]}>
          <Col xl={9} lg={7}>
            {data?.headings?.[0] && (
              <StyledHeading
                as={data.headings[0]?.level}
                dangerouslySetInnerHTML={{
                  __html: data.headings[0]?.content,
                }}
              />
            )}
          </Col>
          <Col xl={3} lg={5} className="text-center">
            {data?.buttons?.map(({ id, content, ...rest }) => (
              <Button key={id} m="7px" {...rest} target="_blank">
                {content}
              </Button>
            ))}
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

CTAArea.propTypes = {
  layout: PropTypes.oneOf([1, 2]),
  items: PropTypes.object,
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
  }),
};
CTAArea.defaultProps = {
  layout: 1,
};

export default CTAArea;
